import { useState } from 'react'
import { useLingui } from '@lingui/react'
import * as constants from '@connections/constants'

const {
    QSM_TAB_CARS: CARS,
    QSM_TAB_TRIP: TRIP,
    QSM_TAB_SHOPS: SHOPS,
    QSM_TAB_TRAVEL: TRAVEL,
    QSM_TAB_HOTELS: HOTELS,
    QSM_TAB_FLIGHTS: FLIGHTS,
    QSM_TAB_EXPERTS: EXPERTS,
    QSM_TAB_EXPERIENCES: EXPERIENCES,
    QSM_TAB_FLIGHT_HOTEL: FLIGHT_HOTEL,
} = constants

const useQuickSearchTabs = (availableTabs, openTab, title) => {
    const { i18n } = useLingui()
    const [activeTab, setActiveTab] = useState(openTab)

    const options = [{
        value: FLIGHTS,
        icon: 'plane-departure',
        label: i18n._(/*i18n*/'Flights')
    }, {
        value: HOTELS,
        icon: 'building',
        label: i18n._(/*i18n*/'Hotels')
    }, {
        value: FLIGHT_HOTEL,
        label: i18n._(/*i18n*/'Flight & Hotels')
    }, {
        value: CARS,
        icon: 'car',
        label: i18n._(/*i18n*/'Car rental')
    }, {
        value: EXPERIENCES,
        label: i18n._(/*i18n*/'Experiences')
    }, {
        value: SHOPS,
        icon: 'store',
        label: i18n._(/*i18n*/'Shops')
    }, {
        value: EXPERTS,
        icon: 'user-kid',
        label: i18n._(/*i18n*/'Travel Designers')
    }, {
        value: TRAVEL,
        icon: 'sunset',
        label: i18n._(/*i18n*/'Holidays')
    }, {
        value: TRIP,
        label: title || i18n._(/*i18n*/'Book this trip')
    }]

    const handleChangeTab = (tab) => {
        if (tab === HOTELS) {
            const bookingAidId = process.env.NEXT_PUBLIC_BOOKING_AID_ID
            const bookingBaseUrl = process.env.NEXT_PUBLIC_BOOKING_BASE_URL
            window.open(`${bookingBaseUrl}?aid=${bookingAidId}`, '_blank')
        } else {
            setActiveTab(tab)
        }
    }

    return {
        activeTab,
        onChangeTab: handleChangeTab,
        // Reduce tabs instead of filtering options to maintain tab order as defined in StoryBlok
        tabOptions: availableTabs.reduce((tabs, tab) => {
            const option = options.find(({ value }) => value === tab)
            if (option) {
                return [...tabs, option]
            }
            return tabs
        }, [])
    }
}

export default useQuickSearchTabs
