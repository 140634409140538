import { gql } from 'graphql-request'
import useGraphqlMutation from '../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createCheckout($input: CreateCheckoutInput!) {
        createCheckout(input: $input) {
            redirectUrl
        }
    }
`
export default function useCreateCheckoutMutation(options) {
    return useGraphqlMutation(MUTATION, options)
}
