/* eslint-disable quote-props */
/*eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }]*/

import React, { useState } from 'react'
import { stringify } from 'query-string'
import { addDays, format } from 'date-fns'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next-nprogress-bar'
import * as constants from '@connections/constants'
import { getFullLocale, getSimpleLocaleFromPathname } from '@connections/utils'
import useLogEvent from '../hooks/useLogEvent'
import useNotifications from '../hooks/useNotifications'
import useLocaleNavigateTo from '../hooks/useLocaleNavigateTo'
import useFlightSearchExperiment from '../hooks/useFlightSearchExperiment'
import QueryParamProvider from '../providers/QueryParamProvider'
import { FLIGHT_SEARCH_PATHS } from '../../util/routes'
import { useFlightSearchMetaData } from '../../util/analytics/flights'
import useCreateJourneyMutation from '../utils/useCreateJourneyMutation'
import QuickSearchSection from './QuickSearchSection'
import useCreateCheckoutMutation from './useCreateCheckoutMutation'
import useCreateItineraryMutation from './useCreateItineraryMutation'
import { useQuickSearchFlightQueryParams } from './useQuickSearchFlightFormState'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_FR: FR,
    FLIGHT_MODE_MULTI_CITY: MULTI_CITY,
    FLIGHT_MODE_ROUND_TRIP: ROUND_TRIP,
} = constants

const submitSearchCars = (values, locale) => {
    const {
        pickUpDate,
        pickUpTime,
        dropOffDate,
        dropOffTime,
        pickUpDestination,
        dropOffDestination,
        pickupIsSameAsDropOff,
    } = values
    let localeCode = 'NL'
    if (locale === FR) {
        localeCode = 'FR'
    }
    if (locale === EN) {
        localeCode = 'EN'
    }
    const data = {
        ak: '1456719',
        l: localeCode,
        pt: pickUpTime,
        dt: dropOffTime,
        prid: pickUpDestination.locationId,
        pd: format(pickUpDate, 'dd.MM.yyyy'),
        dd: format(dropOffDate, 'dd.MM.yyyy'),
    }
    if (!pickupIsSameAsDropOff) {
        data.drid = dropOffDestination.locationId
    }
    const query = stringify(data)
    window.open(
        `https://booking.sunnycars.${locale === FR ? 'fr' : 'nl'}?${query}`,
        '_blank'
    )
}

export const getDestinationCodeWithPrefix = ({ isMetropolitan, code }) => {
    if (isMetropolitan) {
        return `C-${code}`
    }
    return `A-${code}`
}

const handleFindBookingHotels = (
    alsoFindHotels,
    destination,
    startDate,
    endDate,
    adults,
    children,
    infants
) => {
    if (alsoFindHotels) {
        const bookingAidId = process.env.NEXT_PUBLIC_BOOKING_AID_ID
        const bookingSearchUrl = process.env.NEXT_PUBLIC_BOOKING_SEARCH_URL

        const data = {
            aid: bookingAidId,
            group_adults: adults,
            ss: destination.name,
            group_children: children + infants,
            checkin: format(startDate, 'yyyy-MM-dd'),
            checkout: endDate
                ? format(endDate, 'yyyy-MM-dd')
                : format(addDays(startDate, 4), 'yyyy-MM-dd'),
        }
        const query = stringify(data)
        window.location = `${bookingSearchUrl}?${query}`
    }
}

export const useFlightsSearch = () => {
    const router = useRouter()
    const pathname = usePathname()
    const logEvent = useLogEvent()
    const { dispatchError } = useNotifications()
    const [, setParams] = useQuickSearchFlightQueryParams()
    const { mutateAsync: createJourney } = useCreateJourneyMutation()
    const { flightSearchQueryToSearchStartMetaData } = useFlightSearchMetaData()

    return async (input) => {
        const {
            fareType,
            flightMode,
            returnDate,
            toDestination,
            departureDate,
            alsoFindHotels,
            numberOfAdults,
            includeLuggage,
            isDirectFlight,
            numberOfInfants,
            fromDestination,
            numberOfChildren,
            airlineIataCodes,
            extraSegments = [],
        } = input
        let { journeyUid } = input

        const isMultiCity = flightMode === MULTI_CITY
        const isRoundTrip = flightMode === ROUND_TRIP
        const from = [
            getDestinationCodeWithPrefix(fromDestination),
            ...(isMultiCity
                ? extraSegments.map((segment) =>
                      getDestinationCodeWithPrefix(segment.fromDestination)
                  )
                : []),
        ]
        const to = [
            getDestinationCodeWithPrefix(toDestination),
            ...(isMultiCity
                ? extraSegments.map((segment) =>
                      getDestinationCodeWithPrefix(segment.toDestination)
                  )
                : []),
        ]
        const departure = [
            format(departureDate, 'yyyy-MM-dd'),
            ...(isMultiCity
                ? extraSegments.map((segment) =>
                      format(segment.departureDate, 'yyyy-MM-dd')
                  )
                : []),
        ]

        // Create a journey if we don't have one yet
        const hasInitialJourneyUid = !!journeyUid
        if (!hasInitialJourneyUid) {
            try {
                const data = await createJourney()
                journeyUid = data?.createJourney?.journey?.uid
            } catch (e) {
                dispatchError(e.message)
            }
        }

        const params = {
            to,
            from,
            fareType,
            departure,
            journeyUid,
            includeLuggage,
            isDirectFlight,
            alsoFindHotels,
            mode: flightMode,
            adults: numberOfAdults,
            infants: numberOfInfants,
            children: numberOfChildren,
            airlineIataCodes: airlineIataCodes?.replace(',', '_'),
            return: isRoundTrip ? format(returnDate, 'yyyy-MM-dd') : null,
        }

        const [_, locale, ...slugParts] = pathname.split('/')
        const slug = `/${slugParts.join('/')}`
        const query = {
            ...params,
            to: to.join('_'),
            from: from.join('_'),
            departure: departure.join('_'),
            includeLuggage: includeLuggage ? 1 : 0,
            isDirectFlight: isDirectFlight ? 1 : 0,
            alsoFindHotels: alsoFindHotels ? 1 : 0,
        }

        const fullLocale = getFullLocale(locale)
        const searchPathname = `/${fullLocale}${
            FLIGHT_SEARCH_PATHS[fullLocale]
        }?${stringify(query)}`

        const metaData = flightSearchQueryToSearchStartMetaData(searchPathname)
        const eventType = hasInitialJourneyUid ? 'SearchAgain' : 'InitialSearch'
        logEvent(eventType, metaData, journeyUid)

        if (alsoFindHotels) {
            // Open search in new tab and open hotels in current tab when also searching for hotels
            window.open(`${window.location.origin}${searchPathname}`)
            handleFindBookingHotels(
                alsoFindHotels,
                toDestination,
                departureDate,
                returnDate,
                numberOfAdults,
                numberOfChildren,
                numberOfInfants
            )
        } else if (Object.values(FLIGHT_SEARCH_PATHS).includes(slug)) {
            // When already on the search page we only have to replace the params
            setParams(params, 'replace')
        } else {
            router.push(searchPathname)
        }
    }
}

const QuickSearchSectionWithState = ({
    uid,
    title,
    onOpen,
    spacing,
    onClose,
    anchorId,
    tabs = [],
    container,
    background,
    values = {},
    nezasaTripId,
    openTab = null,
    promoValues = {},
    isCollapsed = false,
    isStandalone = false,
    isPageSearching = false,
    disableScrollIntoView = false,
}) => {
    const router = useRouter()
    const pathname = usePathname()
    const navigateTo = useLocaleNavigateTo()
    const submitSearchFlights = useFlightsSearch()
    const { updatedUi } = useFlightSearchExperiment()
    const locale = getSimpleLocaleFromPathname(pathname)
    const [isSearching, setIsSearching] = useState(false)
    const { mutateAsync: createCheckout } = useCreateCheckoutMutation()
    const { mutateAsync: createItinerary } = useCreateItineraryMutation()

    const handleSearch = async (handler) => {
        setIsSearching(true)
        await handler
        setIsSearching(false)
    }

    const handleSearchCars = (input) => {
        handleSearch(submitSearchCars(input, locale))
    }

    const handleSearchFlights = (input) => {
        if (updatedUi && onClose) {
            onClose()
        }
        handleSearch(submitSearchFlights(input, locale))
    }

    const handleSearchExperiences = ({ type, destination }) => {
        const params = {}
        if (type) {
            params.type = type
        }
        if (destination) {
            if (destination.isContinent) {
                params.continentCode = destination.value
            }
            if (destination.isCountry) {
                params.countryCode = destination.value
            }
            if (destination.isCity) {
                params.iataCode = destination.value
            }
        }
        handleSearch(
            navigateTo({
                pathname: '/intense-ervaringen/zoeken',
                params,
            })
        )
    }

    const handleSearchShops = ({ location, postCode }) => {
        const params = {}
        if (location) {
            params.code = location
        }
        if (postCode) {
            params.code = postCode
        }
        handleSearch(
            navigateTo({
                pathname: '/reiswinkels',
                params,
            })
        )
    }

    const handleSearchExperts = ({ destination }) => {
        const params = {}
        if (destination) {
            if (destination.isContinent) {
                params.continentCode = destination.value
            }
            if (destination.isCountry) {
                params.countryCode = destination.value
            }
            if (destination.isCity) {
                params.iataCode = destination.value
            }
        }
        handleSearch(
            navigateTo({
                pathname: '/travel-designers/zoeken',
                params,
            })
        )
    }

    const handleSearchTravel = (params) => {
        handleSearch(navigateTo({ pathname: '/reizen/zoeken', params }))
    }

    const handleStartTrip = (adults, children, departureDate) => {
        handleSearch(
            (async () => {
                const input = {
                    adults,
                    children,
                    tripId: nezasaTripId,
                    departureDate: departureDate.toString(),
                }
                const {
                    createItinerary: { id: itineraryId },
                } = await createItinerary({ input })
                if (process.env.NEXT_PUBLIC_TRIP_EDITOR === 'nezasa') {
                    router.push(
                        `${process.env.NEXT_PUBLIC_NEZASA_BASE_URL}/itineraries/${itineraryId}`
                    )
                } else {
                    const checkoutInput = { itineraryId }
                    await createCheckout({ input: checkoutInput })
                    router.push(
                        `${
                            process.env.NEXT_PUBLIC_CHECKOUT_APP_URL
                        }/${getFullLocale(
                            locale
                        )}/IBE/itinerary/${itineraryId}/checkout/${itineraryId}/travelers`
                    )
                }
            })()
        )
    }

    return (
        <QuickSearchSection
            uid={uid}
            tabs={tabs}
            title={title}
            values={values}
            onOpen={onOpen}
            openTab={openTab}
            spacing={spacing}
            onClose={onClose}
            anchorId={anchorId}
            container={container}
            background={background}
            promoValues={promoValues}
            isCollapsed={isCollapsed}
            isStandalone={isStandalone}
            onSearchTrip={handleStartTrip}
            onSearchCars={handleSearchCars}
            onSearchShops={handleSearchShops}
            onSearchTravel={handleSearchTravel}
            onSearchFlights={handleSearchFlights}
            onSearchExperts={handleSearchExperts}
            isSearching={isSearching || isPageSearching}
            onSearchExperiences={handleSearchExperiences}
            onScrollIntoView={() => {
                if (!disableScrollIntoView && !!anchorId) {
                    const yOffset = -150
                    const element = document.getElementById(anchorId)
                    if (element) {
                        const { top } = element.getBoundingClientRect()
                        const y = top + window.pageYOffset + yOffset
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            }}
        />
    )
}

export default (props) => (
    <QueryParamProvider>
        <QuickSearchSectionWithState {...props} />
    </QueryParamProvider>
)
