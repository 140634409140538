import React from 'react'
import NextImage from 'next/image'

const IMAGE_FIXED_PATH = '/image/upload'
const STORYBLOK_FIXED_PATH = 'https://img2.storyblok.com'

export const storyblokLoader = ({
    src,
    width,
    height = 0,
    quality,
}) => {
    const resource = src.split('a.storyblok.com/')[1]
    return `${STORYBLOK_FIXED_PATH}/${width}x${height}/smart/filters:quality(${quality})/${resource}`
}

export const cloudinaryLoader = ({
    src,
    zoom,
    width,
    height,
    quality = 100,
    crop = 'fill',
    gravity = 'auto',
}) => {
    const fixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_FIXED_PATH
    const oldFixedPath = process.env.NEXT_PUBLIC_CLOUDINARY_OLD_FIXED_PATH

    const modifiers = [`c_${crop}`, `g_${gravity}`, `q_${quality}`, `w_${width}`]
    if (height) {
        modifiers.push(`h_${height}`)
    }
    if (zoom) {
        modifiers.push(`z_${zoom}`)
    }
    const isOld = src.includes(oldFixedPath)
    const path = `${fixedPath}${IMAGE_FIXED_PATH}`
    let pathToSplit = `${fixedPath}${IMAGE_FIXED_PATH}`
    if (isOld) {
        pathToSplit = `${oldFixedPath}${IMAGE_FIXED_PATH}`
    }
    const [, dynamicPart] = src.split(pathToSplit)
    return `${path}/${modifiers.join(',')}/${dynamicPart}`
}

export const createLoader = ({
    maxWidth,
    maxHeight,
    ...other
}) => ({
    src,
    height,
    width = 0,
    quality = 85,
}) => {
        let finalWidth = maxWidth
        if (!maxWidth || maxWidth > width) {
            finalWidth = width
        }
        let finalHeight = maxHeight
        if (!maxHeight || maxHeight > height) {
            finalHeight = height
        }
        const props = {
            ...other,
            src,
            width: finalWidth,
            height: finalHeight,
            quality,
        }
        if (src.includes('tripbuilder')) {
            return src
        }
        if (src.includes('storyblok')) {
            return storyblokLoader(props)
        }
        return cloudinaryLoader(props)
    }

const Image = ({
    crop,
    zoom,
    gravity,
    maxWidth,
    maxHeight,
    ...props
}) => (
    <NextImage
        loader={createLoader({
            crop,
            zoom,
            gravity,
            maxWidth,
            maxHeight,
        })}
        {...props}
    />
)

export default Image
