import { gql } from 'graphql-request'
import useGraphqlMutation from '../hooks/useGraphqlMutation'

const MUTATION = gql`
    mutation createItinerary($input: CreateItineraryInput!) {
        createItinerary(input: $input) {
            id
        }
    }
`
export default function useCreateItineraryMutation(options) {
    return useGraphqlMutation(MUTATION, options)
}
