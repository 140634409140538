import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import Button from '../utils/Button'
import GuestsSelect from '../utils/GuestsSelect'
import useGuestsState from '../utils/useGuestsState'
import QuickSearchSectionDesktopInputGroup from './QuickSearchSectionDesktopInputGroup'
import QuickSearchSectionDesktopFlightsDateSelect from './QuickSearchSectionDesktopFlightsDateSelect'

const QuickSearchSectionDesktopTripWithState = ({
    onSearch,
    isSearching,
}) => {
    const {
        adults,
        children,
        numberOfRooms,
        handleChangeAdults,
        handleChangeChildren,
        handleChangeChildAge,
        handleChangeGuestState,
        handleChangeNumberOfRooms,
    } = useGuestsState()
    const [departureDate, setDepartureDate] = useState(null)

    const currentGuestsState = {
        numberOfRooms,
        adults,
        children,
    }

    return (
        <div className="flex flex-wrap gap-4">
            <QuickSearchSectionDesktopInputGroup
                className="flex-1 min-w-[14rem]"
                label={<Trans id="Departure date" />}
            >
                <QuickSearchSectionDesktopFlightsDateSelect
                    errors={[]}
                    date={departureDate}
                    onChange={setDepartureDate}
                />
            </QuickSearchSectionDesktopInputGroup>
            <QuickSearchSectionDesktopInputGroup
                label={<Trans id="Travelers" />}
                className="flex-1 min-w-[14rem]"
            >
                <GuestsSelect
                    listOfAdults={adults}
                    listOfChildren={children}
                    numberOfRooms={numberOfRooms}
                    onChangeAdults={handleChangeAdults}
                    onChangeChildren={handleChangeChildren}
                    onChangeChildAge={handleChangeChildAge}
                    onChangeNumberOfRooms={handleChangeNumberOfRooms}
                    onClose={() => handleChangeGuestState(currentGuestsState)}
                />
            </QuickSearchSectionDesktopInputGroup>
            <div className="flex-1 flex items-end">
                <Button
                    size="large"
                    className="flex-1"
                    isLoading={isSearching}
                    variant="primary-filled"
                    onClick={() => onSearch(adults, children, departureDate)}
                    data-testid="QuickSearchSectionDesktopFlightsSearchButton"
                >
                    <Trans id="Start your journey" />
                </Button>
            </div>
        </div>
    )
}

export default QuickSearchSectionDesktopTripWithState
