import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import Icon from '../utils/Icon'
import useQuickSearchTabs from '../hooks/useQuickSearchTabs'
import QuickSearchSectionMobileContainer from './QuickSearchSectionMobileContainer'
import QuickSearchSectionMobileSelectModal from './QuickSearchSectionMobileSelectModal'
import QuickSearchSectionMobileCarsWithState from './QuickSearchSectionMobileCarsWithState'
import QuickSearchSectionMobileShopsWithState from './QuickSearchSectionMobileShopsWithState'
import QuickSearchSectionMobileFlightAndHotel from './QuickSearchSectionMobileFlightAndHotel'
import QuickSearchSectionMobileTravelWithState from './QuickSearchSectionMobileTravelWithState'
import QuickSearchSectionMobileFlightsWithState from './QuickSearchSectionMobileFlightsWithState'
import QuickSearchSectionMobileExpertsWithState from './QuickSearchSectionMobileExpertsWithState'
import QuickSearchSectionMobileExperiencesWithState from './QuickSearchSectionMobileExperiencesWithState'
import QuickSearchSectionMobileTripWithState from './QuickSearchSectionMobileTripWithState'

const {
    QSM_TAB_CARS: CARS,
    QSM_TAB_TRIP: TRIP,
    QSM_TAB_SHOPS: SHOPS,
    QSM_TAB_TRAVEL: TRAVEL,
    QSM_TAB_EXPERTS: EXPERTS,
    QSM_TAB_FLIGHTS: FLIGHTS,
    QSM_TAB_EXPERIENCES: EXPERIENCES,
    QSM_TAB_FLIGHT_HOTEL: FLIGHT_HOTEL,
} = constants

const QuickSearchSectionMobile = ({
    uid,
    tabs,
    title,
    values,
    onOpen,
    openTab,
    spacing,
    onClose,
    isLoading,
    background,
    isCollapsed,
    isSearching,
    promoValues,
    isStandalone,
    onSearchCars,
    onSearchTrip,
    onSearchShops,
    onSearchTravel,
    onSearchExperts,
    onSearchFlights,
    onSearchExperiences,
}) => {
    const {
        activeTab,
        tabOptions,
        onChangeTab,
    } = useQuickSearchTabs(tabs, openTab, title)
    const [isTabsModalOpen, setTabsModelOpen] = useState(false)

    const hasMultipleTabs = tabOptions.length > 1
    const activeTabOption = tabOptions.find(({ value }) => value === activeTab) || {}

    return (
        <>
            {isTabsModalOpen && (
                <QuickSearchSectionMobileSelectModal
                    value={activeTab}
                    options={tabOptions}
                    className="h-[400px]"
                    title={<Trans id="Catalog" />}
                    onClose={() => setTabsModelOpen(false)}
                    onConfirm={(tab) => {
                        onChangeTab(tab)
                        setTabsModelOpen(false)
                    }}
                />
            )}
            <QuickSearchSectionMobileContainer
                spacing={spacing}
                background={background}
                isCollapsed={isCollapsed}
                isStandalone={isStandalone}
            >
                {isCollapsed ? null : (
                    <div className="flex justify-between items-center mb-6">
                        <button
                            type="button"
                            className="text-lg font-medium flex items-center"
                            onClick={() => {
                                if (hasMultipleTabs) {
                                    setTabsModelOpen(true)
                                }
                            }}
                        >
                            <Icon name={activeTabOption.icon} className="mr-2" size="small" />
                            <h3>
                                {activeTabOption.label}
                            </h3>
                            {hasMultipleTabs && (
                                <Icon name="chevron-down" />
                            )}
                        </button>
                        {onClose ? (
                            <button type="button" onClick={onClose}>
                                <Icon name="cross" size="small" />
                            </button>
                        ) : null}
                    </div>
                )}
                {(() => {
                    if (activeTab === FLIGHTS) {
                        return (
                            <QuickSearchSectionMobileFlightsWithState
                                uid={uid}
                                onOpen={onOpen}
                                values={values}
                                isLoading={isLoading}
                                isCollapsed={isCollapsed}
                                isSearching={isSearching}
                                promoValues={promoValues}
                                onSearch={onSearchFlights}
                            />
                        )
                    }
                    if (activeTab === FLIGHT_HOTEL) {
                        return (
                            <QuickSearchSectionMobileFlightAndHotel
                                uid={uid}
                                onSearch={() => { }}
                            />
                        )
                    }
                    if (activeTab === CARS) {
                        return (
                            <QuickSearchSectionMobileCarsWithState
                                uid={uid}
                                onSearch={onSearchCars}
                                pickUpIataCode={values.fromIataCode}
                                dropOffIataCode={values.toIataCode}
                            />
                        )
                    }
                    if (activeTab === EXPERIENCES) {
                        return (
                            <QuickSearchSectionMobileExperiencesWithState
                                uid={uid}
                                onSearch={onSearchExperiences}
                                iataCode={values.toIataCode}
                            />
                        )
                    }
                    if (activeTab === TRAVEL) {
                        return (
                            <QuickSearchSectionMobileTravelWithState
                                uid={uid}
                                onSearch={onSearchTravel}
                            />
                        )
                    }
                    if (activeTab === SHOPS) {
                        return (
                            <QuickSearchSectionMobileShopsWithState
                                uid={uid}
                                onSearch={onSearchShops}
                            />
                        )
                    }
                    if (activeTab === EXPERTS) {
                        return (
                            <QuickSearchSectionMobileExpertsWithState
                                uid={uid}
                                onSearch={onSearchExperts}
                                iataCode={values.toIataCode}
                            />
                        )
                    }
                    if (activeTab === TRIP) {
                        return (
                            <QuickSearchSectionMobileTripWithState
                                onSearch={onSearchTrip}
                                isSearching={isSearching}
                            />
                        )
                    }
                    return null
                })()}
            </QuickSearchSectionMobileContainer>
        </>
    )
}

export default QuickSearchSectionMobile
