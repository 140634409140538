import React, { useEffect, useState } from 'react'
import { Trans } from '@lingui/react'
import DateButton from '../utils/DateButton'
import DateCalendarMobileModal from '../utils/DateCalendarMobileModal'

const QuickSearchSectionMobileFlightsDateSelect = ({
    date,
    errors,
    viewDate,
    disabled,
    className,
    onConfirm,
    dayIsDisabledCondition,
    isOpen: isOpenFromProps,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const hasError = errors.length > 0

    useEffect(() => {
        setIsOpen(isOpenFromProps)
    }, [isOpenFromProps])

    return (
        <>
            {isOpen && (
                <DateCalendarMobileModal
                    initialDate={date}
                    viewDate={viewDate}
                    onClose={() => setIsOpen(false)}
                    title={<Trans id="Departure" />}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    onConfirm={(day) => {
                        onConfirm(day)
                        setIsOpen(false)
                    }}
                />
            )}
            <DateButton
                date={date}
                hasError={hasError}
                disabled={disabled}
                className={className}
                testId="DateButtonDeparture"
                onClick={() => setIsOpen(true)}
                placeholder={<Trans id="Departure" />}
            />
        </>
    )
}

export default QuickSearchSectionMobileFlightsDateSelect
